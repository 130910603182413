import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="LUCRAREA CU FEMEILE" items={items}>
      <SEO
        title="Lucrarea cu femeile"
        keywords={[`lucrarea cu femeile`, `fundația ebe`]}
      />
      <P style={{ textAlign: 'center' }}>
        <P>Coordonator Lucrarea cu Femeile din cadrul Fundației EBE Romania.</P>
        <P>
          <i>Lidia Șchiopu</i>
        </P>
      </P>

      <P>
        Am fost formată / echipată prin slujirea misionarelor din cadrul
        organizației BEEI, (actualmente “Entrust”) fiind parte din primul lor
        grup de femei din Timișoara anului 1986. La început participam la grup
        din dorința de a învăța pentru mine însumi, dar pe măsură ce viața mi se
        schimba, am simțit treptat și responsabilitatea de a împărtăși și altor
        femei ceea ce învățam. În anul 1995 am renunțat la servici pentru a mă
        dedica deplin slujirii altor femei. Pentru mai bine de doi ani de zile
        pot spune că am trăit depinzând total de Dumnezeu, experimentând astfel
        credincioșia Lui în fiecare zi.
      </P>
      <P>
        În anul 1998 am fost solicitată să coordonez lucrarea femeilor din
        cadrul Fundației EBE. Acceptând această provocare, am fost nevoită să
        lucrez cu femei din toate confesiunile evanghelice, fapt care s-a arătat
        în cele din urmă de a fi foarte benefic pentru Împărăția lui Dumnezeu.
      </P>
      <P>
        Pasiunea mea cea mai mare este să văd femeile crescând în maturitate,
        slujind altor femei și trăindu-și viața pentru slava lui Dumnezeu. Mă
        bucur mult de ajutorul soțului meu, Dorel. Avem împreună patru copii și
        ne bucurăm și de primul nepoțel.
      </P>
      <P>
        <b>Cu ce ne ocupăm?</b>
      </P>
      <P>
        Departamentul lucrării cu femeile din cadrul EBE România, se ocupă de
        echiparea și formarea femeilor pentru a sluji altor femei. Ne dorim în
        bisericile noastre femei mature, echipate pentru slujire, care
        descoperindu-și darurile, să-și aducă propria contribuție la lărgirea și
        edificarea Bisericii lui Isus Hristos. Acest lucru îl dorim cu atât mai
        mult cu cât știm că în bisericile noastre peste 60% sunt femei. Ce
        potențial fantastic, dar uneori, atât de neglijat!
      </P>
      <P>
        <b>Ce pot face femeile?</b> Pavel îi scrie lui Tit și îi dă instrucțiuni
        clare și concrete despre modul în care să se ocupe de fiecare segment
        din biserică, iar la un moment dat el spune: dar vreau “ca femeile în
        vârstă … să le învețe pe cele tinere să-și iubească bărbații și copiii,
        să fie cumpătate, cu viață curată, să-și vadă de treburile casei, să fie
        bune, supuse bărbaților lor, pentru ca să nu se vorbească de rău
        Cuvântul lui Dumnezeu “.(Tit 2 :4-5)
      </P>
      <P>
        <b>Ce facem noi?</b> Vrem să împlinim această poruncă, echipând femei
        care să le învețe pe altele cum să trăiască vieți care să-i aducă cinste
        lui Dumnezeu, cum să devină făcătoare de ucenici – mandatul fiecărui
        creștin! Pentru aceasta creem și oferim cadrul în care femeile să
        crească din punct de vedere spiritual și să fie echipate. Folosim mai
        ales filosofia de lucrare în grupuri mici de ucenicie și creștere, dar
        și seminarii, conferințe, asistență, mentorare. Lucrăm interconfesional,
        cu toate bisericile evanghelice, trecând dincolo de diferențe și
        bucurându-ne de ceea ce avem în comun. De fapt, între noi femeile, în
        multe locuri au căzut zidurile denominaționale și am învățat să ne
        acceptăm, să ne respectăm, să ne slujim. știm că suntem parte din
        aceeași BISERICĂ a lui Isus Hristos și …. ne pregătim să-I fim Mireasa!
      </P>
      <P>
        Într-o vreme în care mulți creștini mai degrabă împrumută valorile
        lumii, noi dorim să transmitem generației în care investim, valorile
        eterne ale Împărăției lui Dumnezeu. Credem că{' '}
        <b>Intimitatea cu Dumnezeu</b>
        este vitală, vrem să trăim vieți de <b>Integritate</b>, vrem să fim
        Invățabile, să lucrăm împreună în Echipă, vrem să slujim{' '}
        <b>sub Autoritatea Bisericii locale</b> și în tot ce suntem și facem
        vrem să fim <b>Dependente de Dumnezeu!</b>
      </P>
      <P>
        Uitându-mă prin credință, pot vedea cum Dumnezeu ridică o generație a
        “femeilor aducătoare de vești bune”, care vor trăi făcând o diferență
        pentru El în această țară și până la marginile pământului.
      </P>
    </PageWithLeftMenu>
  )
}
